$brand-yellow: #fdce1c;
$brand-dark-purple: #401e58;
$brand-light-gray: #f6f6fa;
$brand-saturated-purple: #521ec9;
$brand-saturated-purple-lighter: #6029df;
$brand-purple-hover: #552e70;
$brand-pastel-purple: #f8f7fb;
$brand-pastel-purple-darker: #d8d1de;
$section-light-gray-background: #fefefe;
$line-light-color: $brand-light-gray;
$footer-font-color: rgba(255, 255, 255, 0.83);
$basic-body-color: #666666;
$basic-headlings-color: #2b2b2b;
$form-message-color: #4dbf8b;
$shadow-color: rgba(33, 25, 44, 0.1);
$hover-shadow-color: rgba(33, 25, 44, 0.15);
$title-body-color: #401e58;
$divider-color: #e4e0ee;
$article-content-color: #424242;
$input-background-color: #ffffff;
$input-background-color-readonly: #f7f7f7;
$input-label: #696969;
$input-border-color: $article-content-color;
$input-border-color-light: #eaeaea;
$link-color: #054ada;
$text-normal: #2B2B2B;
$border-medium: #E5E5E5;
$article-background-color: rgba(96, 41, 223, 0.035);
$success-color: #4dbf8b;
$error-color: #e95b5b;
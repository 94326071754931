@import 'mixins/breakpoints';

*,
::before,
::after {
  box-sizing: border-box;
}

:root {
  --plyr-color-main: #fdce1c;
}

html {
  line-height: 1.75;
  -webkit-text-size-adjust: 100%;
  font-size: 15px;
  font-family: NeueFrutiger, system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji';
  font-weight: 400;
  text-rendering: geometricPrecision;
  text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  margin: 0;
  scroll-padding-top: 130px;
}

body {
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
}

b,
strong {
  font-weight: 600;
}

small {
  font-size: 80%;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  text-transform: none;
}

textarea {
  display: block;
}

[hidden] {
  display: none !important;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

.table-of-contents {
  padding: 20px 20px;
  border: 1px solid #e3e3e3;
  display: none;

  @include for-size-large {
    display: block;
  }

  p,
  ol {
    font-size: 16px !important;
  }

  p {
    margin: 0;
    font-weight: 500;
  }

  ol {
    margin: 0;
    padding: 0 20px;
    list-style-type: numeric;
    font-weight: 300;
    counter-reset: item;

    li {
      display: block;
    }

    li::before {
      content: counters(item, '.') '. ';
      counter-increment: item;
    }
  }

  a {
    color: #054ada;
  }
}

p,
div {
  a.markdown-cta {
    background-color: #521ec9;
    color: #fff !important;
    border: 2px solid #521ec9;
    font-weight: 600;
    font-size: 15px;
    padding: 7px 20px;
    margin: 15px 0;
    border-radius: 1000px;
    display: inline-block;
    transition: all 0.3s;

    &:first-child:not(:last-child) {
      margin-top: 0;
    }

    &:last-child:not(:first-child) {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #6029df;
      border: 2px solid #6029df;
      text-decoration: none;
    }
  }

  .text-align-center {
    text-align: center;
  }

  .markdown-highlight {
    box-shadow: 1px 11px 31px rgba(33, 25, 44, 0.1);
    border-radius: 5px;
    margin: 50px 0;
    overflow: hidden;
  }

  .markdown-highlight {
    font-size: 0.8rem;

    code {
      width: 100%;
      max-width: 100%;
      overflow: auto;
      max-height: 50vh;
      padding: 20px;
    }
  }

  .markdown-highlight--embed {
    display: flex;
    overflow: auto;
    position: relative;

    iframe {
      width: 100% !important;
      height: 100% !important;
      aspect-ratio: 16 / 9;
      border: none;
      position: relative !important;
    }
  }
}